import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../config';
import Sidebar from '../../Components/sidebar/Sidebar';

class EditActualite extends Component {
    constructor() {
        super();
        this.state = {
            actualite: {},
            titre: '',
            type: '',
            provenance: '',
            description: '',
            contenu: '',
            redirect: false,
            isActualiteNotExits: false,
            isLoading: false,
        }
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const {actualite_id} = this.props.match.params;
        const token = sessionStorage.getItem('token');

        if(token){
            Axios.get(`${url}/api/office/actualites/${actualite_id}?token=${token}`)
            .then(res => {
                const {data} = res;
                if(data.status === 'error') { this.setState({ isActualiteNotExits: true }) }
                else {
                    this.setState({ actualite: data.actualite });
                }
            });
        } else {
            this.props.history.goBack();
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'titre':
                this.setState({titre: value});
                break;
            case 'provenance':
                this.setState({provenance: value});
                break;
            case 'type':
                this.setState({type: value});
                if(value === 'video') {
                    this.setState({provenance: 'externe'})
                } else {
                    this.setState({provenance: ''});
                }
                break;
            case 'description':
                this.setState({description: value});
                break;
            case 'contenu':
                if(this.state.provenance === 'interne') {
                    this.setState({contenu: e.target.files[0]});
                } else if(this.state.provenance === 'externe') {
                    this.setState({contenu: value});
                }
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});

        const token = sessionStorage.getItem('token');
        
        const {titre, actualite, provenance, contenu, type, description} = this.state;

        let formData = new FormData();
        formData.append('actualite_id', actualite.id);
        if(provenance !== '') {formData.append('titre', titre);}
        if(provenance !== '') {formData.append('provenance', provenance);}
        if(description !== '') {formData.append('description', description);}
        if(contenu !== '') {formData.append('contenu', contenu);}
        if(type !== '') {formData.append('type', type);}

        Axios.post(`${url}/api/office/edit-actualite?token=${token}`, formData)
        .then(
            res => {
                const {status} = res.data;
                this.setState({isLoading: false, redirect: true});
            },

            err => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    renderNotExistsAlert = () => {
        return (
            <div className="alert alert-info">La magazine sélectionnée n'existe pas !</div>
        )
    }

    renderContenuField = () => {
        if(this.state.provenance === 'interne') {
            return (
                <>
                    <label>Contenu de l'actualité</label>
                    <input
                        type="file"
                        name="contenu"
                        className="form-control"
                        onChange={this.handleChange}
                    />
                </>
            )
        } else if(this.state.provenance === 'externe') {
            let label = this.state.type === 'video' ? 'Lien Youtube' : 'Contenu de l\'actualité';
            return (
                <>      
                    <label>{label}</label>
                    <input
                        type="text"
                        name="contenu"
                        placeholder="Collez le lien ici ..."
                        value={this.state.contenu}
                        className="form-control"
                        onChange={this.handleChange}
                    />
                </>
            )
        }
        
    }

    renderForm = () => {
        const {isLoading} = this.state;
        return (
            <div className="col-md-12 card">
                { this.state.isActualiteNotExits ? '' : this.renderTable() }
                <div className="col-md-8 pt-3 mx-auto" style={{ minHeight: '70vh' }}>
                    <div className="card-body">                           
                        <form data-form="multipart" onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Titre de l'Actualité</label>
                                    <input
                                        type="text"
                                        name="titre"
                                        placeholder="Nouveau titre ..."
                                        value={this.state.titre}
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Type de média de l'Actualité</label>
                                    <select
                                        name="type"
                                        value={this.state.type}
                                        className="form-control"
                                        onChange={this.handleChange}
                                    >
                                        <option value=''>Choisir le type de contenu</option>
                                        <option value='image'>Image</option>
                                        <option value='video'>Vidéo</option>
                                    </select>
                                </div>
                            </div>
                            {
                                this.state.type === 'image'
                                ?   <div className="form-row">
                                        <div className="form-group col">
                                            <label>Nature de l'Actualité</label>
                                            <select 
                                                name="provenance"
                                                value={this.state.provenance}
                                                className="form-control"
                                                onChange={this.handleChange}
                                            >
                                                <option value=''>Choisir la nature de l'actu</option>
                                                <option value='externe'>Lien externe</option>
                                                <option value='interne'>Fait maison</option>
                                            </select>
                                        </div>
                                    </div>
                                :   ''
                            }

                            <div className="form-group">
                                { this.state.provenance !== '' ? this.renderContenuField() : '' }
                            </div>

                            <div className="form-group">
                                <label>Description de l'Actualité</label>
                                <textarea 
                                    name="description"
                                    value={this.state.description}
                                    placeholder="description Actualité"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    rows="6"
                                ></textarea>
                            </div>

                            <div className="form-group">
                                {   isLoading ?
                                        <button className="btn btn-lg btn-block btn-primary" disabled>
                                            <div className="d-flex justify-content-center">
                                                <strong>Loading...</strong>
                                            </div>
                                        </button>
                                    :   <input type="submit" value="Mettre à jour" className="btn btn-block btn-primary" />
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    renderTable = () => {
        const {actualite} = this.state;
        let imgUrl = `${url}/public/actualites`;

        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Provenance</th>
                            <th>type</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">{ actualite.titre }</th>
                            <td>{ actualite.provenance }</td>
                            <td>{ actualite.type }</td>
                            <td>
                                {actualite.description ? actualite.description.substring(0, 50) : ''}
                            </td>
                            <td>
                                <button type="button" className="btn btn-sm btn-info mr-2" data-toggle="modal" data-target={`#editActuModal${actualite.id}Scrollable`}><i className="fas fa-eye"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="modal fade" id={`editActuModal${actualite.id}Scrollable`} tabIndex="-1" role="dialog" aria-labelledby="showContenuModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="showContenuModalTitle">Edition Actualité</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-md-12 mx-auto">
                                    {
                                        actualite.type === 'image'
                                        ?   <img className="col-md-12 px-0"  style={{maxHeight: 360 + 'px'}} src={actualite.contenu} />
                                        :   <iframe id="actu-video" width="740" height="360" src={actualite.contenu} frameborder="0" allowfullscreen></iframe>
                                    }
                                </div>
                                <div className="col-md-12 mt-2 text-justify">{actualite.description}</div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        const { redirect, isActualiteNotExits } = this.state;

        if(redirect) {
            return <Redirect to='/actualites' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-actualite" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Edition Actualité</h2>
                        </div>

                        { isActualiteNotExits ? this.renderNotExistsAlert() : this.renderForm() }

                    </div>
                </div>
            </>
        );
    }
}

export default EditActualite;