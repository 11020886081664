import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../config';
import './Categories.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class EditCategorie extends Component {
    constructor() {
        super();
        this.state = {
            categorie: {},
            nom: '',
            error: {
                nom: ''
            },
            isCategorieNotExits: false,
            redirect: false,
            isLoading: false,
        }
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const {categorie_id} = this.props.match.params;
        const token = sessionStorage.getItem('token');

        if(token) {
            Axios.get(`${url}/api/office/categories/${categorie_id}?token=${token}`)
            .then(res => {
                const {data} = res;
                const {status} = data;
                status === 'error' ? this.setState({ isCategorieNotExits: true }) : this.setState({ categorie: data.categorie })
                console.log('old categorie', this.state.categorie);
            });
        } else {
            this.props.history.goBack();
        }

    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'nom':
                this.setState({nom: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const token = sessionStorage.getItem('token');

        const {categorie, nom, error} = this.state;
        let formData = new FormData();

        formData.append('categorie_id', categorie.id);
        if(nom !== '') {formData.append('nom', nom);}

        Axios.post(`${url}/api/office/edit-categorie?token=${token}`, formData).then(
            res => {
                const {status} = res.data;
                if(status === 'error') {
                    const {errors} = res.data;
                    let {error} = this.state;

                    if(errors.nom !== undefined) {
                        error.nom = errors.nom[0]
                    } else {error.nom = ''}

                    this.setState({isLoading: false, error});
                } else {
                    this.setState({isLoading: false, redirect: true});
                }
            },

            (err) => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    renderNotExistsAlert = () => {
        return (
            <div className="alert alert-info">La Catégorie sélectionnée n'existe pas !</div>
        )
    }

    renderForm = () => {
        const {error, isLoading} = this.state;
        return (                           
            <form data-form="multipart" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>Nouveau Nom de la Catégoire</label>
                    <input 
                        type="text"
                        name="nom"
                        value={this.state.nom}
                        placeholder="Nom Catégorie"
                        className="form-control"
                        onChange={this.handleChange}
                    />
                    { error.nom !== null ? <small className="form-text text-danger ml-3">{error.nom}</small> : null}
                </div>
                <div className="form-group mt-4">
                    {   isLoading ?
                            <button className="btn btn-lg btn-block btn-primary" disabled>
                                <div className="d-flex justify-content-center">
                                    <strong>Loading...</strong>
                                </div>
                            </button>
                        :   <input type="submit" value="Valider les modifications" className="btn btn-lg btn-block btn-primary" />
                    }
                </div>
            </form>
        )
    }

    renderTable = () => {
        const {categorie} = this.state;

        return(
            <table className="table table-striped table-bordered card-text">
                <tbody>
                    <tr>
                        <th>Nom</th>
                        <td className="text-center">{categorie.nom}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    render() {
        const { redirect, isCategorieNotExits } = this.state;

        if(redirect) {
            return <Redirect to='/categories' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-chronique" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">
                                { isCategorieNotExits ? 'Edition Chronique' : `Edition Chronique ${this.state.categorie.nom}` }
                            </h2>
                        </div>

                        <div className="col-md-12 card">
                            <div className="col-md-12 pt-3 mx-auto" style={{ minHeight: '70vh' }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 pt-4">
                                            { isCategorieNotExits ? '' : this.renderTable() }
                                        </div>
                                        <div className="col-md-5 offset-1">
                                            { isCategorieNotExits ? this.renderNotExistsAlert() : this.renderForm() }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EditCategorie;