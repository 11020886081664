import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../config';
import './Chroniques.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class EditChronique extends Component {
    constructor() {
        super();
        this.state = {
            categories: [],
            chronique: {},
            titre: '',
            description: '',
            categorie_id: '',
            error: {
                titre: '',
                description: '',
                categorie_id: '',
            },
            isChroniqueNotExits: false,
            redirect: false,
            isLoading: false,
        }
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const {chronique_id} = this.props.match.params;
        const token = sessionStorage.getItem('token');

        if(token) {
            Axios.get(`${url}/api/office/chroniques/${chronique_id}?token=${token}`)
            .then(res => {
                const {data} = res;
                const {status} = data;
                status === 'error' ? this.setState({ isChroniqueNotExits: true }) : this.setState({ chronique: data.chronique })
                console.log('old chronique', this.state.chronique);
                this.getCategories(token);
            });
        } else {
            this.props.history.goBack();
        }
    }

    getCategories(token) {
        Axios.get(`${url}/api/office/categories?token=${token}`)
        .then(res => {
            this.setState({ 
                categories: res.data.categories
            })
        });
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'titre':
                this.setState({titre: value});
                break;
            case 'description':
                this.setState({description: value});
                break;
            case 'categorie_id':
                this.setState({categorie_id: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const token = sessionStorage.getItem('token');

        const {chronique, titre, description, categorie_id, error} = this.state;
        let formData = new FormData();

        formData.append('chronique_id', chronique.id);
        if(titre !== '') {formData.append('titre', titre);}
        if(description !== '') {formData.append('description', description);}
        if(categorie_id !== '') {formData.append('categorie_id', categorie_id);}

        Axios.post(`${url}/api/office/edit-chronique?token=${token}`, formData).then(
            res => {
                const {status} = res.data;
                if(status === 'error') {
                    const {errors} = res.data;
                    let {error} = this.state;

                    if(errors.titre !== undefined) {
                        error.titre = errors.titre[0]
                    } else {error.titre = ''}
                    if(errors.description !== undefined) {
                        error.description = errors.description[0]
                    } else {error.description = ''}
                    if(errors.categorie_id !== undefined) {
                        error.categorie_id = errors.categorie_id[0]
                    } else {error.categorie_id = ''}

                    this.setState({isLoading: false, error});
                } else {
                    this.setState({isLoading: false, redirect: true});
                }
            },

            (err) => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    renderNotExistsAlert = () => {
        return (
            <div className="alert alert-info">La Chronique sélectionnée n'existe pas !</div>
        )
    }

    renderForm = () => {
        const {error, isLoading, categories} = this.state;
        return (                           
            <form data-form="multipart" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>Nouveau Titre de la Chronique</label>
                    <input 
                        type="text"
                        name="titre"
                        value={this.state.titre}
                        placeholder="Titre Chronique"
                        className="form-control"
                        onChange={this.handleChange}
                    />
                    { error.titre !== null ? <small className="form-text text-danger ml-3">{error.titre}</small> : null}
                </div>

                <div className="form-group">
                    <label>Nouvelle Catégorie de la Chronique</label>
                    <select 
                        name="categorie_id"
                        value={this.state.categorie_id}
                        className="form-control"
                        onChange={this.handleChange}
                    >
                    <option value=''>Catégorie Chronique</option>
                    {
                        categories.map((categorie, index) => (
                            <option key={index} value={categorie.id}>{categorie.nom}</option>
                        ))
                    }
                    </select>
                    { error.categorie_id !== null ? <small className="form-text text-danger ml-3">{error.categorie_id}</small> : null}
                </div>

                <div className="form-group">       
                    <label>Nouvelle Description de la Chronique</label>
                    <textarea
                        type="text"
                        name="description"
                        value={this.state.description}
                        placeholder="Nouvelle Description Chronique"
                        className="form-control"
                        onChange={this.handleChange}
                    >
                    </textarea>
                    { error.description !== null ? <small className="form-text text-danger ml-3">{error.description}</small> : null}
                </div>
                <div className="form-group mt-4">
                    {   isLoading ?
                            <button className="btn btn-lg btn-block btn-primary" disabled>
                                <div className="d-flex justify-content-center">
                                    <strong>Loading...</strong>
                                </div>
                            </button>
                        :   <input type="submit" value="Valider les modifications" className="btn btn-lg btn-block btn-primary" />
                    }
                </div>
            </form>
        )
    }

    renderTable = () => {
        const {chronique} = this.state;

        return(
            <table className="table table-striped table-bordered card-text">
                <tbody>
                    <tr>
                        <th>Titre</th>
                        <td className="text-center">{chronique.titre}</td>
                    </tr>
                    <tr>
                        <th>Catégorie</th>
                        <td className="text-center">{chronique.categorie}</td>
                    </tr>
                    <tr>
                        <th>Description</th>
                        <td className="text-center">{chronique.description}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    render() {
        const { redirect, isChroniqueNotExits } = this.state;

        if(redirect) {
            return <Redirect to='/chroniques' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-chronique" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">
                                { isChroniqueNotExits ? 'Edition Chronique' : `Edition Chronique ${this.state.chronique.titre}` }
                            </h2>
                        </div>

                        <div className="col-md-12 card">
                            <div className="col-md-12 pt-3 mx-auto" style={{ minHeight: '70vh' }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 pt-4">
                                            { isChroniqueNotExits ? '' : this.renderTable() }
                                        </div>
                                        <div className="col-md-5 offset-1">
                                            { isChroniqueNotExits ? this.renderNotExistsAlert() : this.renderForm() }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EditChronique;