import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import {url} from '../../config';
import Sidebar from '../../Components/sidebar/Sidebar';

class NewTome extends Component {
    constructor() {
        super();
        this.state = {
            isChroniqueNotExits: false,
            chronique: {},
            numero: '',
            description: '',
            premiere_page: '',
            contenu: '',
            prix: '',
            publication: '',
            formule: 'payant',
            error: {
                numero: null,
                description: null,
                premiere_page: null,
                contenu: null,
                prix: null,
                publication: null
            },
            redirect: false,
            isLoading: true,
        }
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        const {chronique_id} = this.props.match.params;

        if(token) {
            axios.get(`${url}/api/office/chroniques/${chronique_id}?token=${token}`)
            .then(res => {
                const {data} = res;
                const {status} = data;
                if(status === 'error') {
                    this.setState({
                        isChroniqueNotExits: true,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        chronique: data.chronique,
                        isLoading: false
                    })
                }
            });
        } else {
            this.props.history.goBack();
        }
        
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'numero':
                this.setState({numero: value});
                break;
            case 'description':
                this.setState({description: value});
                break;
            case 'premiere_page':
                this.setState({premiere_page: e.target.files[0]});
                break;
            case 'contenu':
                this.setState({contenu: e.target.files[0]});
                break;
            case 'formule':
                this.setState({formule: value});
                break;
            case 'prix':
                this.setState({prix: value});
                break;
            case 'publication':
                this.setState({publication: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {numero, description, premiere_page, contenu, formule, prix, publication, error} = this.state;

        if(numero === '') {
            error.numero = 'Le numero du tome est obligatoire';
        } else {
            error.numero = null;
        }
        if(description === '') {
            error.description = 'La description du tome est obligatoire';
        } else {
            error.description = null;
        }
        if(premiere_page === '') {
            error.premiere_page = 'Le premiere page du tome est obligatoire';
        } else {
            error.premiere_page = null;
        }
        if(contenu === '') {
            error.contenu = 'Le contenu du tome est obligatoire';
        } else {
            error.contenu = null;
        }
        if(formule !== 'gratuit') {
            if(prix === '') {
                error.prix = 'Le prix du journal est obligatoire';
            } else {
                error.prix = null;
            }
        }
        if(publication === '') {
            error.publication = 'La date de publication du tome est obligatoire';
        } else {
            error.publication = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({error, isLoading: false})
        } else {
            this.sendFormData();
        }
    }

    sendFormData = () => {
        const token = sessionStorage.getItem('token');
        
        let formData = new FormData();
        formData.append('chronique_id', this.state.chronique.id);
        formData.append('numero', this.state.numero);
        formData.append('description', this.state.description);
        formData.append('premiere_page', this.state.premiere_page);
        formData.append('contenu', this.state.contenu);
        formData.append('publication', this.state.publication);
        formData.append('formule', this.state.formule);
        if(this.state.formule === 'gratuit') {
            formData.append('prix', 0);
        } else {
            formData.append('prix', this.state.prix);
        }
        

        axios.post(`${url}/api/office/new-tome?token=${token}`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;
                        
                        if(errors.numero !== undefined) {
                            error.numero = errors.numero[0]
                        } else {error.numero = ''}
                        if(errors.description !== undefined) {
                            error.description = errors.description[0]
                        } else {error.description = ''}
                        if(errors.premiere_page !== undefined) {
                            error.premiere_page = errors.premiere_page[0]
                        } else {error.premiere_page = ''}
                        if(errors.contenu !== undefined) {
                            error.contenu = errors.contenu[0]
                        } else {error.contenu = ''}
                        if(errors.prix !== undefined) {
                            error.prix = errors.prix[0]
                        } else {error.prix = ''}
                        if(errors.publication !== undefined) {
                            error.publication = errors.publication[0]
                        } else {error.publication = ''}

                        this.setState({isLoading: false, error});
                    } else {
                        this.setState({isLoading: false, redirect: true})
                    }
                },

                err => {
                    this.setState({isLoading: false});
                    console.log('error', err);
                }
            )
    }

    render() {
        const { isChroniqueNotExits, chronique, error, redirect, isLoading } = this.state;

        if(redirect) {
            return <Redirect to={`/tomes/${chronique.id}`} />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-tome" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Nouveau Tome de { chronique.titre }</h2>
                        </div>

                        <div className="col-md-12 card">
                            {
                                isChroniqueNotExits
                                ?   <div className="card-body">
                                        <div className="alert alert-warning">
                                            <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                                                La chronique sélectonnée est introuvable
                                            </p>
                                        </div>
                                    </div>
                                :   <div className="col-md-8 pt-4 mx-auto" style={{ minHeight: '70vh' }}>
                                        <div className="card-body">                           
                                            <form data-form="multipart" onSubmit={this.handleSubmit}>
                                                <div className="form-row">
                                                    <div className="form-group col-6">
                                                        <label>Numero du Tome</label>
                                                        <input 
                                                            type="number"
                                                            name="numero"
                                                            value={this.state.numero}
                                                            placeholder="numero Tome"
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                        />
                                                        { error.numero !== null ? <small className="form-text text-danger ml-3">{error.numero}</small> : null}
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>Couverture du Tome</label>
                                                        <input
                                                            type="file"
                                                            name="premiere_page"
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                        />
                                                        { error.premiere_page !== null ? <small className="form-text text-danger ml-3">{error.premiere_page}</small> : null}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-6">
                                                        <label>Contenu du Tome</label>
                                                        <input
                                                            type="file"
                                                            name="contenu"
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                        />
                                                        { error.contenu !== null ? <small className="form-text text-danger ml-3">{error.contenu}</small> : null}
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label>Date de publication du Tome</label>
                                                        <input 
                                                            type="date"
                                                            name="publication"
                                                            value={this.state.parution}
                                                            placeholder="date de publication Tome"
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                        />
                                                        { error.publication !== null ? <small className="form-text text-danger ml-3">{error.publication}</small> : null}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div 
                                                        className={`form-group form-check ${this.state.formule !== 'gratuit' ? 'col-6' : 'col-12'}`}>
                                                        <label>Nature du Tome</label>
                                                        <select 
                                                            type="text"
                                                            name="formule"
                                                            value={this.state.formule}
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                        >
                                                            <option value='payant'>Tome payant</option>
                                                            <option value='gratuit'>Tome gratuit</option>
                                                        </select>
                                                    </div>
                                                    {
                                                        this.state.formule !== 'gratuit'
                                                        ?   
                                                            <div className="form-group col-6">
                                                                <label>Prix du Tome</label>
                                                                <input 
                                                                    type="number"
                                                                    name="prix"
                                                                    value={this.state.prix}
                                                                    placeholder="prix Tome"
                                                                    className="form-control"
                                                                    onChange={this.handleChange}
                                                                />
                                                                { error.prix !== null ? <small className="form-text text-danger ml-3">{error.prix}</small> : null}
                                                            </div>
                                                            
                                                        :   ''
                                                    }
                                                </div>                                                
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label>Description du Tome</label>
                                                        <textarea 
                                                            name="description"
                                                            value={this.state.description}
                                                            placeholder="description Tome"
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                            rows="5"
                                                        ></textarea>
                                                        { error.description !== null ? <small className="form-text text-danger ml-3">{error.description}</small> : null}
                                                    </div>
                                                </div>
                                                <div className="form-group mt-3">
                                                    {   isLoading ?
                                                            <button className="btn btn-lg btn-block btn-primary" disabled>
                                                                <div className="d-flex justify-content-center">
                                                                    <strong>Loading...</strong>
                                                                </div>
                                                            </button>
                                                        :   <input type="submit" value="Ajouter" className="btn btn-block btn-primary" />
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NewTome;