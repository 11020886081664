import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url} from '../../config';
import './Actualites.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Actualites extends Component {
    constructor () {
        super();
        this.state = {
            actualites: [],
            isNoActuExist: false,
            isLoading: true,
        };
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token')
        if(token){
            axios.get(`${url}/api/office/actualites?token=${token}`)
            .then(res => {
                res.data.count > 0 ? this.setState({ actualites: res.data.data, isLoading: false }) : this.setState({ isNoActuExist: true, isLoading: false })
            });
        } else {
            this.props.history.goBack();
        }
    }

    getLinkPreviewer = (link) => {
        console.log("link", link);
    }

    onDelete = (id) => {
        const token = sessionStorage.getItem('token');
        this.setState({isLoading: true});

        axios.get(`${url}/api/office/actualites/${id}/remove?token=${token}`)
        .then(res => {
            let actualites = this.state.actualites.filter(actualite => actualite.id !== id);
            this.setState({actualites, isLoading: false});
        });
    }

    renderNewActuAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucune Actualité n'est encore ajoutée &nbsp; &nbsp; &nbsp; &nbsp;
                    <Link  to="/new-actualite" className="btn btn-sm btn-primary">Ajouter une Actualité</Link>
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {actualites} = this.state;
        let imgUrl = `${url}/public/actualites`;
        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Titre</th>
                            <th>type</th>
                            <th>Provenance</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {actualites.length > 0 ? 
                            actualites.map((actualite, index) => (
                                <tr key={index}>
                                    <th scope="row">{ actualite.titre }</th>
                                    <td>{ actualite.type }</td>
                                    <td>{ actualite.provenance }</td>
                                    <td>
                                        { actualite.description ? actualite.description.substring(0, 50) : '' }
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-sm btn-info mr-2" data-toggle="modal" data-target={`#actuModal${actualite.id}Scrollable`}><i className="fas fa-eye"></i></button>
                                        <Link to={`/edit-actualite/${actualite.id}`} className="btn btn-sm btn-warning mr-2"><i className="fas fa-edit"></i></Link>
                                        <button className="btn btn-sm btn-danger" onClick={() => this.onDelete(actualite.id)}><i className="fas fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            ))
                            : ''
                        }
                    </tbody>
                </table>

                {actualites.map((actualite, index) => (
                    <div key={`${index}s`} className="modal fade" id={`actuModal${actualite.id}Scrollable`} tabIndex="-1" role="dialog" aria-labelledby="showContenuModalTitle" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="showContenuModalTitle">Modal title</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="col-md-12 mx-auto">
                                        {
                                            actualite.type === 'image'
                                            ?   <img className="col-md-12 px-0" style={{maxHeight: 360 + 'px'}} src={actualite.contenu} />
                                            :   <iframe id="actu-video" width="740" height="360" src={actualite.contenu} frameborder="0" allowfullscreen></iframe>
                                        }
                                    </div>
                                    <div className="col-md-12 mt-2 text-justify">{actualite.description}</div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    render() {
        const {isNoActuExist, isLoading} = this.state;
        
        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-actualites" className="row mr-4">
                <div className="col-md-12">
                    <div className="clearfix mb-md-3">
                       <h2 className="float-left">Liste des actualites</h2>
                       <Link to="/new-actualite" className="btn btn-md btn-primary float-right">Nouvelle Actualité</Link>
                    </div>
                </div>

                <div className="col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            { 
                                isLoading ?
                                    <div className="d-flex justify-content-center">
                                        <strong>Loading...</strong>
                                    </div>
                                :   isNoActuExist ? this.renderNewActuAlerte() : this.renderTable() 
                            }
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </>
        );
    }
}

export default Actualites;