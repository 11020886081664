import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url} from '../../config';
import './Categories.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Categories extends Component {
    constructor () {
        super();
        this.state = {
            categories: [],
            isNoCategorieExist: false,
            isParamExists: false,
            isLoading: true,
        };
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(token) {
            axios.get(`${url}/api/office/categories?token=${token}`)
            .then(res => {
                res.data.count > 0 ? this.setState({ categories: res.data.categories, isLoading: false }) 
                                    : this.setState({ isNoCategorieExist: true, isLoading: false })
            });
        } else {
            this.props.history.goBack();
        }
    }

    onDelete = (id) => {
        const token = sessionStorage.getItem('token');
        this.setState({isLoading: true});

        axios.get(`${url}/api/office/categories/${id}/remove?token=${token}`)
        .then(res => {
            let categories = this.state.categories.filter(categorie => categorie.id !== id);
            this.setState({categories, isLoading: false});
        });
    }

    renderNewCategorieAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucune Catégorie n'est encore ajoutée &nbsp; &nbsp; &nbsp; &nbsp;
                    <Link  to="/new-categorie" className="btn btn-sm btn-primary">Ajouter une Catégorie</Link>
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {categories} = this.state;

        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((categorie, index) => (
                            <tr key={index}>
                                <td>{ categorie.nom }</td>
                                <td>
                                    <Link to={`/edit-categorie/${categorie.id}`} className="btn btn-sm btn-warning mr-2"><i className="fas fa-edit"></i></Link>
                                    <button className="btn btn-sm btn-danger" onClick={() => this.onDelete(categorie.id)}><i className="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        )
    }
    
    render() {
        const {isNoCategorieExist, isLoading} = this.state;
        
        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-categories" className="row mr-4">
                        <div className="col-md-12">
                            <div className="clearfix mb-md-3">
                            <h2 className="float-left">Liste des categories</h2>
                                <Link to="/new-categorie" className="btn btn-md btn-primary float-right">Nouvelle Catégorie</Link>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    { 
                                        isLoading ?
                                            <div className="d-flex justify-content-center">
                                                <strong>Loading...</strong>
                                            </div>
                                        :   isNoCategorieExist ? this.renderNewCategorieAlerte() : this.renderTable() 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Categories;