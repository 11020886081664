import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url} from '../../config';
import './Chroniques.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Chroniques extends Component {
    constructor () {
        super();
        this.state = {
            chroniques: [],
            isNoChroniqueExist: false,
            isParamExists: false,
            isLoading: true,
        };
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(token) {
            axios.get(`${url}/api/office/chroniques?token=${token}`)
            .then(res => {
                res.data.count > 0 ? this.setState({ chroniques: res.data.chroniques, isLoading: false }) 
                                    : this.setState({ isNoChroniqueExist: true, isLoading: false })
            });
        } else {
            this.props.history.goBack();
        }
    }

    onDelete = (id) => {
        const token = sessionStorage.getItem('token');
        this.setState({isLoading: true});

        axios.get(`${url}/api/office/chroniques/${id}/remove?token=${token}`)
        .then(res => {
            let chroniques = this.state.chroniques.filter(chronique => chronique.id !== id);
            this.setState({chroniques, isLoading: false});
        });
    }

    renderNewChroniqueAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucune Chronique n'est encore ajoutée &nbsp; &nbsp; &nbsp; &nbsp;
                    <Link  to="/new-chronique" className="btn btn-sm btn-primary">Ajouter une Chronique</Link>
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {chroniques} = this.state;
        let imgUrl = `${url}/public/img`;

        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Description</th>
                            <th>Catégorie</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chroniques.map((chronique, index) => (
                            <tr key={index}>
                                <td>{ chronique.titre }</td>
                                <td>{ chronique.description }</td>
                                <td>{ chronique.categorie }</td>
                                <td>
                                    <Link to={`/tomes/${chronique.id}`} className="btn btn-sm btn-primary mr-2"><i className="fas fa-eye"></i></Link>
                                    <Link to={`/edit-chronique/${chronique.id}`} className="btn btn-sm btn-warning mr-2"><i className="fas fa-edit"></i></Link>
                                    <button className="btn btn-sm btn-danger" onClick={() => this.onDelete(chronique.id)}><i className="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        )
    }
    
    render() {
        const {isNoChroniqueExist, isLoading} = this.state;
        
        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-chroniques" className="row mr-4">
                        <div className="col-md-12">
                            <div className="clearfix mb-md-3">
                            <h2 className="float-left">Mes chroniques</h2>
                                <Link to="/new-chronique" className="btn btn-md btn-primary float-right">Nouvelle Chronique</Link>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    { 
                                        isLoading ?
                                            <div className="d-flex justify-content-center">
                                                <strong>Loading...</strong>
                                            </div>
                                        :   isNoChroniqueExist ? this.renderNewChroniqueAlerte() : this.renderTable() 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Chroniques;