import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url} from '../../config';
import './Journaux.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class JourneauxByMag extends Component {
    constructor () {
        super();
        this.state = {
            magazine: {},
            journaux: [],
            isNoJourExist: false,
        };
    }

    activeTab = (e) => {
        let magazine_name = '';
        setTimeout(() => {
            magazine_name = this.props.match.params.magazine_name;
            console.log('magazine name', magazine_name);
            const token = sessionStorage.getItem('token');
    
            axios.get(`${url}/api/office/get-magazine-by-name/${magazine_name}?token=${token}`)
            .then(res => {
                const { magazine } = res.data
                this.setState({ magazine });
    
                if(magazine) {
                    axios.get(`${url}/api/office/journauxByMag/${magazine.id}?token=${token}`)
                    .then(resJour => {
                        resJour.data.count > 0 
                        ? this.setState({ journaux: resJour.data.journaux, isNoJourExist: false })
                        : this.setState({ isNoJourExist: true })
                    });
                }
            });
        }, 100);
    }

    componentDidMount() {
        const {magazine_name} = this.props.match.params;
        const token = sessionStorage.getItem('token');

        if(token){
            axios.get(`${url}/api/office/get-magazine-by-name/${magazine_name}?token=${token}`)
            .then(res => {
                const { magazine } = res.data
                this.setState({ magazine });
    
                axios.get(`${url}/api/office/journauxByMag/${magazine.id}?token=${token}`)
                .then(resJour => {
                    resJour.data.count > 0 
                    ? this.setState({ journaux: resJour.data.journaux }) 
                    : this.setState({ isNoJourExist: true })
                });
            });
        } else {
            this.props.history.goBack();
        }
    }

    onDelete = (id) => {
        const token = sessionStorage.getItem('token');
        axios.get(`${url}/api/office/journaux/${id}/remove?token=${token}`)
        .then(res => {
            let journaux = this.state.journaux.filter(journal => journal.id !== id);
            this.setState({journaux});
        });
    }

    renderNewJourAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucun Journal n'est encore ajouté à ce Magazine &nbsp; &nbsp; &nbsp; &nbsp;
                    <Link  to="/new-journal" className="btn btn-sm btn-primary">Ajouter un Journal</Link>
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {journaux} = this.state;
        let imgUrl = `${url}/public/img`;

        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Magazine</th>
                            <th>Premiere page</th>
                            <th>Prix</th>
                            <th>Vendus</th>
                            <th>Restants</th>
                            <th>Parution</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {journaux.map((journal, index) => (
                            <tr key={index}>
                                <td>{ journal.magazine.nom }</td>
                                <td><img className="journal-page-list-view" src={`${imgUrl}/${journal.premiere_page}`} alt="premiere page journal" /></td>
                                <td>{ journal.prix } f</td>
                                <td>{ journal.quantite_vendu }</td>
                                <td>{ journal.quantite - journal.quantite_vendu }</td>
                                <td>{ journal.parution }</td>
                                <td>
                                    <button type="button" className="btn btn-sm btn-info mr-2" data-toggle="modal" data-target={`#exampleModal${journal.id}${journal.parution}Scrollable`}><i className="fas fa-eye"></i></button>
                                    <Link to={`/edit-journal/${journal.id}`} className="btn btn-sm btn-warning mr-2"><i className="fas fa-edit"></i></Link>
                                    <button className="btn btn-sm btn-danger" onClick={() => this.onDelete(journal.id)}><i className="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {journaux.map((journal, index) => (
                    <div key={`${index}s`} className="modal fade" id={`exampleModal${journal.id}${journal.parution}Scrollable`} tabIndex="-1" role="dialog" aria-labelledby="showContenuModalTitle" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="showContenuModalTitle">Modal title</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <embed src={`${url}/public/journaux/${journal.contenu}`} width="770" height="500" type='application/pdf'/>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )
    }
    
    render() {
        const {isNoJourExist, magazine} = this.state;
        
        return (
            <>
                <Sidebar activeTab={this.activeTab} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-journaux" className="row mr-4">
                <div className="col-md-12">
                    <div className="clearfix mb-md-3">
                       <h2 className="float-left">Liste des journaux de {magazine.nom}</h2>
                       <Link to="/new-journal" className="btn btn-md btn-primary float-right">Nouveau journal</Link>
                    </div>
                </div>

                <div className="col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            { isNoJourExist ? this.renderNewJourAlerte() : this.renderTable() }
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </>
        );
    }
}

export default JourneauxByMag;