import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../config';
import './Magazines.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class NewMagazine extends Component {
    constructor() {
        super();
        this.state = {
            nom: '',
            couleur: '',
            logo: '',
            error: {
                nom: null,
                couleur: null,
                logo: null
            },
            redirect: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(!token) {
            this.props.history.goBack();
        }
    }

    activeTab = (e) => {
        
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'nom':
                this.setState({nom: value});
                break;
            case 'couleur':
                this.setState({couleur: value});
                break;
            case 'logo':
                this.setState({logo: e.target.files[0]});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {nom, logo, couleur, error} = this.state;

        if(nom === '') {
            error.nom = 'Le nom de la magazine est obligatoire';
        } else {
            error.nom = null;
        }
        if(couleur === '') {
            error.couleur = 'La couleur de la magazine est obligatoire';
        } else {
            error.couleur = null;
        }
        if(logo === '') {
            error.logo = 'Le logo de la magazine est obligatoire';
        } else {
            error.logo = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({error, isLoading: false})
        } else {
            this.sendFormData(nom, couleur, logo);
        }
    }

    sendFormData = (nom, couleur, logo) => {
        const token = sessionStorage.getItem('token');
        
        let formData = new FormData();
        formData.append('nom', nom);
        formData.append('couleur', couleur);
        formData.append('logo', logo);

        Axios.post(`${url}/api/office/new-magazine?token=${token}`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;
                        if(errors.nom !== undefined) {
                            error.nom = errors.nom[0]
                        } else {error.nom = ''}
                        if(errors.couleur !== undefined) {
                            error.couleur = errors.couleur[0]
                        } else {error.couleur = ''}
                        if(errors.logo !== undefined) {
                            error.logo = errors.logo[0]
                        } else {error.logo = ''}
                        this.setState({isLoading: false, error});
                    } else {
                        this.setState({isLoading: false, redirect: true})
                    }
                },

                (err) => {
                    this.setState({isLoading: false});
                    console.log('error', err)
                }
            )
    }

    render() {
        const { error, redirect, isLoading } = this.state;

        if(redirect) {
            return <Redirect to='/magazines' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-magazine" className="row mr-4 ml-3">
                <div className="col-md-12 mb-4">
                    <h2 className="text-center">Nouvelle Magazine</h2>
                </div>

                <div className="col-md-12 card">
                    <div className="col-md-6 pt-5 mx-auto" style={{ minHeight: '70vh' }}>
                        {/* <div className="card-header">
                            <h6 className="text-uppercase mb-0">Striped Table</h6>
                        </div> */}
                        <div className="card-body">                           
                            <form data-form="multipart" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label>Nom de la Magazine</label>
                                    <input 
                                        type="text"
                                        name="nom"
                                        value={this.state.nom}
                                        placeholder="Nom Magazine"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                    { error.nom !== null ? <small className="form-text text-danger ml-3">{error.nom}</small> : null}
                                </div>
                                <div className="form-group">
                                    <label>Couleur de la Magazine</label>
                                    <input 
                                        type="text"
                                        name="couleur"
                                        value={this.state.couleur}
                                        placeholder="Couleur Magazine"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                    { error.couleur !== null ? <small className="form-text text-danger ml-3">{error.couleur}</small> : null}
                                </div>
                                <div className="form-group">       
                                    <label>Logo Magazine</label>
                                    <input
                                        type="file"
                                        name="logo"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                    { error.logo !== null ? <small className="form-text text-danger ml-3">{error.logo}</small> : null}
                                </div>
                                <div className="form-group mt-4">
                                    {   isLoading ?
                                            <button className="btn btn-lg btn-block btn-primary" disabled>
                                                <div className="d-flex justify-content-center">
                                                    <strong>Loading...</strong>
                                                </div>
                                            </button>
                                        :   <input type="submit" className="btn btn-lg btn-block btn-primary" value="Ajouter" />
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </>
        );
    }
}

export default NewMagazine;