import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../config';
import './Journaux.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class EditJournal extends Component {
    constructor() {
        super();
        this.state = {
            journal: {},
            premiere_page: '',
            contenu: '',
            quantite: '',
            prix: '',
            parution: '',
            redirect: false,
            isJournalNotExits: false,
            isLoading: false,
        }
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const {journal_id} = this.props.match.params;
        const token = sessionStorage.getItem('token');

        if(token) {
            Axios.get(`${url}/api/office/journaux/${journal_id}?token=${token}`)
            .then(res => {
                const {data} = res;
                if(data.status === 'error') { this.setState({ isJournalNotExits: true }) }
                else {
                    this.setState({ journal: data.journal });
                }
            });
        } else {
            this.props.history.goBack();
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'premiere_page':
                this.setState({premiere_page: e.target.files[0]});
                break;
            case 'contenu':
                this.setState({contenu: e.target.files[0]});
                break;
            case 'quantite':
                this.setState({quantite: value});
                break;
            case 'prix':
                this.setState({prix: value});
                break;
            case 'parution':
                this.setState({parution: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});

        const token = sessionStorage.getItem('token');
        
        const {journal, premiere_page, contenu, quantite, prix, parution} = this.state;

        let formData = new FormData();
        formData.append('journal_id', journal.id);
        if(premiere_page !== '') {formData.append('premiere_page', premiere_page);}
        if(contenu !== '') {formData.append('contenu', contenu);}
        if(quantite !== '') {formData.append('quantite', quantite);}
        if(prix !== '') {formData.append('prix', prix);}
        if(parution !== '') {formData.append('parution', parution);}

        Axios.post(`${url}/api/office/edit-journal?token=${token}`, formData)
        .then(
            res => {
                const {status} = res.data;
                this.setState({isLoading: false, redirect: true});
            },

            err => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    renderNotExistsAlert = () => {
        return (
            <div className="alert alert-info">La magazine sélectionnée n'existe pas !</div>
        )
    }

    renderForm = () => {
        const {magazines, isLoading} = this.state;
        return(
            <div className="col-md-12 card">
                { this.state.isJournalNotExits ? '' : this.renderTable() }
                <div className="col-md-8 pt-4 mx-auto" style={{ minHeight: '70vh' }}>
                    <div className="card-body">                           
                        <form data-form="multipart" onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Quantité du Journal</label>
                                    <input 
                                        type="number"
                                        name="quantite"
                                        value={this.state.quantite}
                                        placeholder="quantite Journal"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Prix du Journal</label>
                                    <input 
                                        type="number"
                                        name="prix"
                                        value={this.state.prix}
                                        placeholder="prix Journal"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>                                                           
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Première page du Journal</label>
                                    <input
                                        type="file"
                                        name="premiere_page"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Contenu du journal</label>
                                    <input
                                        type="file"
                                        name="contenu"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Date de prution du Journal</label>
                                <input 
                                    type="date"
                                    name="parution"
                                    value={this.state.parution}
                                    placeholder="date de parution Journal"
                                    className="form-control"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group mt-3">    
                                {   isLoading ?
                                        <button className="btn btn-lg btn-block btn-primary" disabled>
                                            <div className="d-flex justify-content-center">
                                                <strong>Loading...</strong>
                                            </div>
                                        </button>
                                    :   <input type="submit" value="Mettre à jour" className="btn btn-block btn-primary" />
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    renderTable = () => {
        const {journal} = this.state;
        let imgUrl = `${url}/public/img`;

        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Magazine</th>
                            <th>Premiere page</th>
                            <th>Prix</th>
                            <th>Vendus</th>
                            <th>Restants</th>
                            <th>Parution</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ journal.magazine ? journal.magazine.nom : '' }</td>
                            <td><img className="journal-page-list-view" src={`${imgUrl}/${journal.premiere_page}`} alt="premiere page journal" /></td>
                            <td>{ journal.prix } f</td>
                            <td>{ journal.quantite_vendu }</td>
                            <td>{ (journal.quantite * 1) - (journal.quantite_vendu * 1) }</td>
                            <td>{ journal.parution }</td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-info mr-2"
                                    data-toggle="modal"
                                    data-target={`#editModal${journal.id}${journal.parution}Scrollable`}
                                ><i className="fas fa-eye"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="modal fade" id={`editModal${journal.id}${journal.parution}Scrollable`} tabIndex="-1" role="dialog" aria-labelledby="showContenuModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="showContenuModalTitle">Modal title</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <embed src={`${url}/journaux/${journal.contenu}`} width="770" height="500" type='application/pdf'/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        const { redirect, isJournalNotExits } = this.state;

        if(redirect) {
            return <Redirect to='/journaux' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-journal" className="row mr-4 ml-3">
                <div className="col-md-12 mb-4">
                    <h2 className="text-center">Edition Journal</h2>
                </div>

                { isJournalNotExits ? this.renderNotExistsAlert() : this.renderForm() }
                
            </div>
                </div>
            </>
        );
    }
}

export default EditJournal;