import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url} from '../../config';
import './Chroniqueurs.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Chroniqueurs extends Component {
    constructor () {
        super();
        this.state = {
            chroniqueurs: [],
            isNoChroniqueurExist: false,
            isLoading: true,
        };
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        this.getChroniqueurs();
    }

    getChroniqueurs = () => {
        const token = sessionStorage.getItem('token');
        if(token) {
            axios.get(`${url}/api/office/chroniqueurs?token=${token}`)
            .then(res => {
                res.data.count > 0 ? 
                    this.setState({ chroniqueurs: res.data.data, isLoading: false }) 
                    : this.setState({ isNoChroniqueurExist: true, isLoading: false })
            });
        } else {
            this.props.history.goBack();
        }

    }

    onToggleChroniqueurStatus = (id) => {
        const token = sessionStorage.getItem('token');

        axios.get(`${url}/api/office/toggle-chroniqueur-status/${id}?token=${token}`)
        .then(res => {
            this.getChroniqueurs();
        });
    }

    renderNewChroniqueurAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucun Chroniqueur n'est encore ajouté &nbsp; &nbsp; &nbsp; &nbsp;
                    <Link  to="/new-chroniqueur" className="btn btn-sm btn-primary">Ajouter un Chroniqueur</Link>
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {chroniqueurs} = this.state;
        let imgUrl = `${url}/public/img`;
        return (
            <table className="table table-striped card-text">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {chroniqueurs.map((chroniqueur, index) => (
                        <tr key={index}>
                            <td><img className="avatar-list-view" src={`${imgUrl}/${chroniqueur.avatar}`} alt={`Avatar chroniqueur`}/></td>
                            <td>{ chroniqueur.prenom }</td>
                            <td>{ chroniqueur.nom }</td>
                            <td>{ chroniqueur.email }</td>
                            <td>{ chroniqueur.telephone }</td>
                            <td>
                                { chroniqueur.status ? <span className='text-success'>Actif</span> : <span className='text-danger'>Inactif</span> }
                            </td>
                            <td>
                                {
                                    chroniqueur.status
                                    ?   <button className="btn btn-sm btn-danger mr-2" onClick={() => this.onToggleChroniqueurStatus(chroniqueur.id)}>Désactiver</button>
                                    :   <button className="btn btn-sm btn-success" onClick={() => this.onToggleChroniqueurStatus(chroniqueur.id)}>Activer</button>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    render() {
        const {isNoChroniqueurExist, isLoading} = this.state;

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-chroniqueurs" className="row mr-4">
                <div className="col-md-12">
                    <div className="clearfix mb-md-3">
                       <h2 className="float-left">Liste des chroniqueurs</h2>
                       <Link to="/new-chroniqueur" className="btn btn-md btn-primary float-right">Nouveau Chroniqueur</Link>
                    </div>
                </div>

                <div className="col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            { 
                                isLoading ?
                                    <div className="d-flex justify-content-center">
                                        <strong>Loading...</strong>
                                    </div>
                                :   isNoChroniqueurExist ? this.renderNewChroniqueurAlerte() : this.renderTable()
                            }
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </>
        );
    }
}

export default Chroniqueurs;